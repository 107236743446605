import { locales, localeCodes } from "./resources/i18n";
import enTranslations from "./resources/en-translations.json";

const defaultFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
  },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
  },
};

const createDateTimeFormats = (locales: string[]) => {
  return locales.reduce((dateTimeFormats: any, locale) => {
    dateTimeFormats[locale] = defaultFormat;

    return dateTimeFormats;
  }, {});
};

export default defineI18nConfig(() => ({
  legacy: false,
  locales,
  locale: "en",
  fallbackLocale: "en",
  warnHtmlMessage: false,
  datetimeFormats: createDateTimeFormats(localeCodes),
  messages: enTranslations,
}));
