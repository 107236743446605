import type { LocaleObject } from "@nuxtjs/i18n";

export const locales: LocaleObject[] = [
  { code: "en", iso: "en", dir: "ltr" },
  { code: "es", iso: "es", dir: "ltr" },
  { code: "ja", iso: "ja", dir: "ltr" },
  { code: "pt", iso: "pt", dir: "ltr" },
  { code: "ar", iso: "ar", dir: "rtl" },
  { code: "fr", iso: "fr", dir: "ltr" },
  { code: "de", iso: "de", dir: "ltr" },
  { code: "tr", iso: "tr", dir: "ltr" },
  { code: "hi", iso: "hi", dir: "ltr" },
  { code: "ko", iso: "ko", dir: "ltr" },
  { code: "ru", iso: "ru", dir: "ltr" },
  { code: "tl", iso: "tl", dir: "ltr" },
  { code: "th", iso: "th", dir: "ltr" },
  { code: "ms", iso: "ms", dir: "ltr" },
];

export const localeCodes = locales.map((locale) => locale.code);
